<template>
  <div class="body_section">
    <profileHeader
      :showFilter="true"
      @changedGlobalclient="changedGlobalclient"
      ref="profile_hrader"
    />
    <div class="body_content">
      <div class="main_content">
        <div class="subheader">
          <div>
            <input
              class="search"
              type="text"
              v-model="serch_title"
              @keyup="serchTitle()"
              :placeholder="
                userdata.roleId == 5
                  ? 'Search by State'
                  : 'Search by business name and state'
              "
            />
          </div>
          <div class="d-flex">
            <filingsFilters
              @applyFilter="applyFilters"
              @clearFilter="clearFilters"
              :filterStatus="filterStatus"
              :selectedGlobalClient="selectedGlobalClient"
              :recordsCount="recordsCount"
              @fileExport="fileExport"
            ></filingsFilters>
          </div>
        </div>
        <div class="list_table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" v-if="userdata.roleId != 5">
                  <a
                    @click="sortMe('clientName')"
                    v-bind:class="{
                      sort_ascending: sortKeys['clientName'] == 1,
                      sort_descending: sortKeys['clientName'] != 1,
                    }"
                    >Business Name</a
                  >
                </th>
                <th scope="col">
                  <a
                    @click="sortMe('stateName')"
                    v-bind:class="{
                      sort_ascending: sortKeys['stateName'] == 1,
                      sort_descending: sortKeys['stateName'] != 1,
                    }"
                  >
                    State
                  </a>
                </th>
                <th scope="col">Period</th>
                <th scope="col">
                  <a
                    @click="sortMe('amount')"
                    v-bind:class="{
                      sort_ascending: sortKeys['amount'] == 1,
                      sort_descending: sortKeys['amount'] != 1,
                    }"
                    >Tax
                  </a>
                </th>
                <th scope="col">
                  <a
                    @click="sortMe('totalAmount')"
                    v-bind:class="{
                      sort_ascending: sortKeys['totalAmount'] == 1,
                      sort_descending: sortKeys['totalAmount'] != 1,
                    }"
                    >Grossed Sales
                  </a>
                </th>
                <th scope="col">
                  <a
                    @click="sortMe('taxedAmount')"
                    v-bind:class="{
                      sort_ascending: sortKeys['taxedAmount'] == 1,
                      sort_descending: sortKeys['taxedAmount'] != 1,
                    }"
                    >Taxed Sales
                  </a>
                </th>
                <th scope="col">
                  <a
                    @click="sortMe('dueDate')"
                    v-bind:class="{
                      sort_ascending: sortKeys['dueDate'] == 1,
                      sort_descending: sortKeys['dueDate'] != 1,
                    }"
                    >Due Date</a
                  >
                </th>
                <!--<th scope="col">Filed On</th>-->
                <th scope="col" class="w-180">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in list">
                <tr :key="index">
                  <td v-if="userdata.roleId != 5">
                    <!-- <router-link :to="'/filing-details/'+item._id" class="mar"> -->
                    <figure @click="setGloalClient(item)" class="d-inline-flex">
                      <span>
                        <img
                          @error="setAltImg($event)"
                          :src="checkProperty(item, 'clientDetails', 'logo')"
                        />
                      </span>
                      <figcaption>
                        {{ checkProperty(item, "clientDetails", "busName") }}
                      </figcaption>
                    </figure>
                    <!-- </router-link>                     -->
                  </td>
                  <td>
                    <figure>
                      <a href="#">{{
                        checkProperty(item, "stateDetails", "name")
                      }}</a>
                    </figure>
                  </td>
                  <td>
                    {{ item.filingStartDate | formatDate }} -
                    {{ item.filingEndDate | formatDate }}
                  </td>
                  <td>{{ item["amount"].toFixed(2) | currency }}</td>
                  <td>{{ item["totalAmount"].toFixed(2) | currency }}</td>
                  <td>{{ item["taxedAmount"].toFixed(2) | currency }}</td>
                  <td>
                    <template v-if="checkProperty(item, 'dueDate') != '--'">
                      {{ checkProperty(item, "dueDate") | formatDate }}
                    </template>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link :to="'/filing-details/' + item._id"
                        >DETAILS</router-link
                      >
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <template v-if="showLoading">
            <span class="no_data list_loader"
              >Loading<img src="@/assets/images/loader.gif"
            /></span>
          </template>
          <template v-if="list.length <= 0 && loaded && !showLoading">
            <span class="no_data">No Data!</span>
          </template>
        </div>

        <div class="pagination_wrap">
          <div v-show="list.length > 0">
            <div class="pagination_content">
              <h6>Show Records</h6>
              <multiselect
                :multiple="false"
                @input="changePerPage()"
                placeholder="Page"
                :close-on-select="true"
                :clear-on-select="false"
                :hide-selected="true"
                :preserve-search="true"
                v-model="perPage"
                :options="perPeges"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} selected</span
                  >
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && isOpen"
                  ></span>
                </template>
              </multiselect>
            </div>
          </div>

          <b-pagination
            v-if="list.length > 0"
            v-model="page"
            :total-rows="totalPages"
            :per-page="perPage"
            pills
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>

<script>
import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";

import Multiselect from "vue-multiselect-inv";
import filingsFilters from "@/views/filters/transactionsFilters.vue";
import _ from "lodash";
import axios from "@/axios.js";
export default {
  name: "notes",
  methods: {
    fileExport(filterData) {
      document.cookie = "accessToken=" + this.userdata["accessToken"];

      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;
      this.filterData.dueDateRange = filterData.dueDateRange;
      let APIURL = axios.defaults["baseURL"];

      let postData = {
        filters: this.filterData,

        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };

      this.$store
        .dispatch("getExportFilePatch", {
          data: postData,
          path: "/deadlines/export",
        })
        .then((response) => {
          this.exportData = response;
          //alert(JSON.stringify(APIURL+this.exportData['path']));
          //window.location.href = APIURL+"viewfile?path="+this.exportData['path'];
          window.open(
            APIURL + "viewfile?path=" + this.exportData["path"],
            "_blank"
          );
          //viewfile?path=
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: error.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    setGloalClient(item) {
      if (this.userdata["roleId"] != 5) {
        try {
          let cl = {
            _id: item.clientDetails["_id"],
            name: item.clientDetails.busName,
          };
          alert(JSON.stringify(cl));
          this.$store.dispatch("globalClient", cl);
          localStorage.setItem("selectedGlobalClient", JSON.stringify(cl));
          this.$refs.profile_hrader.setGlClient(cl);
          setTimeout(() => {
            this.$router.push("/dashboard");
          }, 1);
        } catch (err) {
          this.log(err);
        }
      }
    },
    changedGlobalclient(cl) {
      this.selectedGlobalClient = cl;
      this.filterStatus = false;
      this.filterStatus = true;

      //this.$refs.rout_view.serchTitle(cl);
    },
    getList() {
      this.loaded = false;
      let postData = {
        filters: this.filterData,

        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      postData["filters"]["title"] = this.serch_title;

      if (!this.callfromSort) this.list = [];
      this.callfromSort = false;
      this.$store
        .dispatch("getList", { data: postData, path: "/deadlines/list" })
        .then((response) => {
          this.list = response.list;
          this.recordsCount = this.list.length;
          this.totalPages = response.totalCount;
          this.loaded = true;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    changePerPage() {
      this.page = 1;
      this.getList();
    },
    applyFilters(filterData) {
      //this.filterData = filterData: { "title": "","clientIds":[] ,"statusIds": [], "stateIds": [], "createdOnRange": []},

      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;
      this.filterData.dueDateRange = filterData.dueDateRange;

      this.getList();
    },
    clearFilters() {
      this.serch_title = "";
      (this.filterData = {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
        dueDateRange: [],
      }),
        this.getList();
    },

    serchTitle() {
      this.page = 1;
      this.getList();
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "dueDate", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.callfromSort = true;
        this.getList();
      }
    },
    UpdateAdjustMentAmount(item) {
      if (_.has(item, "filedAmount") && _.has(item, "amount")) {
        return item["amount"] - item["filedAmount"];
      } else {
        return "0:00";
      }
    },
  },
  data() {
    return {
      loaded: false,
      recordsCount: 0,
      callfromSort: false,
      selectedGlobalClient: null,
      filterStatus: true,
      userdata: null,
      isLoaded: false,
      loginUser: null,
      page: 1,
      perPage: 25,
      totalPages: 0,
      list: [],
      sortKey: {},
      perPeges: [10, 25, 50, 75, 100],
      filterData: {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
        dueDateRange: [],
      },
      serch_title: "",
      sortKeys: {
        stateName: 1,
        clientName: 1,
        createdOn: -1,
        dueDate: 1,
        amount: 1,
      },
    };
  },
  components: {
    filingsFilters,
    Multiselect,
    profileHeader,
    footerView,
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.isLoaded = true;
    this.sortKey = { path: "dueDate", order: 1 };

    this.sortKeys = {
      stateName: -1,
      clientName: -1,
      amount: -1,
      createdOn: -1,
      taxedAmount: -1,
      totalAmount: -1,
      dueDate: 1,
    };
    this.selectedGlobalClient = this.$store.getters["getGlobalClient"];
    this.changedGlobalclient(this.selectedGlobalClient);
    if (
      this.selectedGlobalClient != null &&
      _.has(this.selectedGlobalClient, "_id")
    ) {
      this.filterData.clientIds.push(this.selectedGlobalClient["_id"]);
    }

    this.getList();
  },
  watch: {
    page: function(val) {
      this.log(val);
      this.getList();
    },
  },
};
</script>
