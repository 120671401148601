<template>
     <div class="filters_sec" :class="{'open':openFilter}">
     <!--{{ this.selectedCreatedByList}} ------------------ {{selectedGlobalClient}}-->
        <button :disabled="recordsCount<=0" v-if=" current_route ==='transactions' || 'fillings' == current_route || current_route== 'deadlines' " @click='applyFilters(true)' class="export_btn">Export</button>
        <button @click="toggleFilter()" class="filters_btn">Filters </button>
        <div class="filters_cnt ">
       
            <div class="row">

            <div class="filters_list col" v-if="'transactions' != current_route  && 'todos' != current_route && current_route!= 'deadlines'  ">
                    <h6>Status {{current_route}}</h6>
                   
                    <multiselect
                    v-model="selected_status"
                        :value="'_id'"
                        :options="user_status"
                        :searchable="true"
                        :close-on-select="false"
                        :hide-selected="true"
                        :allow-empty="true"
                        :multiple="true"
                        label="name"
                        placeholder="Select status"
                        track-by="name"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} Status selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                    <span slot="noResult">Status not found</span>
                    </multiselect>
            </div>
            <div class="filters_list col" v-if="selectedGlobalClient==null && userdata.roleId!=5" >
                <h6>Client</h6>
                    
                        <multiselect
                        v-model="selectedCreatedByList"
                            :value="'_id'"
                            :options="createdByList"
                            :searchable="true"
                            :close-on-select="false"
                            :allow-empty="true"
                            :multiple="true"
                            :hide-selected="true"
                            label="name"
                            placeholder="Select Client"
                            track-by="name"
                            @search-change="asyncCreatedByList"
                        >
                        <template slot="selection" slot-scope="{ values, isOpen }">
                            <span
                            class="multiselect__selectcustom"
                            v-if="values.length && !isOpen"
                            >{{ values.length }} Clients selected</span
                            >
                            <span
                            class="multiselect__selectcustom"
                            v-if="values.length && isOpen"
                            ></span>
                        </template>
                        <span slot="noResult">Clients not found</span>
                        </multiselect>
                    

                        
                    
                    <!--<ul class="uploaded-list">
                    <li v-for="(assigned ,index) in taggedToDetails"  :key="index">
                    {{assigned.name}}
                    <span @click="removeItem(index ,taggedToDetails)">X</span>
                    </li>
                </ul>-->

            </div>
            
            <div class="filters_list col-md">
                <h6>States</h6>
                <multiselect
                v-model="selectedUsstateList"
                    :value="'_id'"
                    :options="usstateList"
                    :searchable="true"
                    :close-on-select="false"
                    :allow-empty="true"
                    :multiple="true"
                    :hide-selected="true"
                    label="name"
                    placeholder="Select state"
                    track-by="name"
                >
                <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                    class="multiselect__selectcustom"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} States selected</span
                    >
                    <span
                    class="multiselect__selectcustom"
                    v-if="values.length && isOpen"
                    ></span>
                </template>
                <span slot="noResult">States not found</span>
                </multiselect>
            </div>

           <div class="filters_list col" v-if="'transactions' == current_route">
                    <h6>Type</h6>
                   
                    <multiselect
                    v-model="selectedTaxable"
                        :value="'_id'"
                        :options="listTaxable"
                        :searchable="false"
                        :close-on-select="false"
                        :hide-selected="false"
                        :allow-empty="true"
                        :multiple="true"
                        label="name"
                        placeholder="Select Type"
                        track-by="name"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} Selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                    <span slot="noResult">Taxable not found</span>
                    </multiselect>
            </div>
            
            
            <div class="filters_list col" v-if="'todos' != current_route && (currentRoute =='transactions')">
               
               
                 <h6  >Created Date</h6>
                 
                <date-range-picker :disabledDates="disabledDates"  :max-date="new Date()"   :maxDate="new Date()" :opens="'left'" :autoApply="autoApply" :ranges="false"   v-model="selected_createdDateRange"></date-range-picker>
            </div>

            <!--- selected_filedOnRange --->
            <div class="filters_list col" v-if="currentRoute =='fillings'">
               
                <h6 >Filed Date </h6>
                <date-range-picker :disabledDates="disabledDates"  :max-date="new Date()"  :maxDate="new Date()" :opens="'left'" :autoApply="autoApply" :ranges="false"   v-model="selected_filedOnRange"></date-range-picker>
            </div>

            

            <div class="filters_list col-md" v-if="'todos' != current_route && currentRoute !='transactions'" >
            
                <h6>Due Date</h6> 

                <date-range-picker placeholder="YYYY-MM-DD"  :autoApply="autoApply" :opens="'left'" :ranges="false"   v-model="selected_dueDateRange"></date-range-picker>
            </div>
            </div>
            <div class="filter_actions">
                <button class="secondary_btn" @click="clearFilter()">Clear<i class="fas fa-redo"></i></button>
                <div class="d-flex">
                    <button class="filter_close secondary_btn" @click="openFilter=false;toggleBodyClass('removeClass', 'hidden')">Cancel</button>
                    <button class="filter_close primary_btn" @click="applyFilters()">Apply</button>
                </div>
            </div>
        </div>
       
        <span class="filters_layer"  @click="toggleFilter()"></span>
    </div>
</template>
<script>
import _ from "lodash"; 
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import Multiselect from "vue-multiselect-inv";

//import { VueAutosuggest } from 'vue-autosuggest'; 

export default {
    props:{
        filterStatus:{
            type:Boolean,
            default:false
        },
        recordsCount:{
            type:Number,
            default:0
        },
    selectedGlobalClient:{"name":'',"_id":''},
    },
   
   
    components: {
        DateRangePicker,
        Multiselect,
       // VueAutosuggest
    },
    data() {
        return {
            isLoaded:false,
        // filterData: { "title": "","categoryIds": [], "statusIds": [], "taggedToIds": [], "createdByIds": [],"createdOnRange": []},
            fromFillingDetails:false,
            user_role: [],
            user_status: [{"_id":1  ,"name":"Pending"} ,{"_id":2 ,"name":"Filed"}],
            note_access_level:[],//[{ item: 1, name: 'Internal' },{ item: 2, name: 'Clients' }, { item: 3, name: 'Custom' },],
            selected_status: [],
            selected_createdDateRange:{},
            selected_dueDateRange:{},
            autoApply: "",
            searchText:'',
            taggedToDetails:[],
            clientList:[],
            selected_note_access_level:[],
            createdByList:[],
            selectedCreatedByList:[],
            tagToList:[],
            categoryIds:[],
            selected_categoryIds:[],
            usstateList:[],
            selectedUsstateList:[],
            taxName:'',
           selectedTaxable:[],
           listTaxable:[{"_id":1,"name":'Taxable'} ,{"_id":2 ,"name":'Non Taxable'}],
           selected_filedOnRange : {},
            disabledDates: {
                from: new Date(),
            },

        }
    },
    methods: {
         getUsStatesList(){
            
        let payLoad = {
		"matcher": {
			"searchString":"",
			//"cntryId": 101,
			//"stateId": 665,
			"cntryIds": [231], // Requied for "state" 
			//"stateIds": [665], // Requied for "city"
		},
		"page": 1,
		"perpage": 10000000000,
		"category": "state", // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
	} ;
         this.$store.dispatch("getMaterData",payLoad)
         .then((res)=>{
             this.usstateList = res.list;
              this.selectedUsstateList =[];
             if(this.$route.query.stateId){
                
                 _.forEach(this.usstateList ,(item)=>{
                     if(item._id ==this.$route.query.stateId ){
                        
                         this.selectedUsstateList =[];
                         this.selectedUsstateList.push(item);
                        
                        

                     }

                 });
                
              
             }
              this.applyFilters();
            
             

         });
    },
        asyncFind(query){
            this.tagToList = [];
            this.searchText = query;
            this.getCliList(true)
        },
       
        asyncCreatedByList(query){
            this.createdByList = [];
            this.searchText = query;
            this.getCliList(false)
        },
        getMaterData(category){
           
            let payLoad = {
            "matcher": {
                "searchString":"",
            },
            "page": 1,
            "perpage": 10000000000,
            "category": category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
            } ;
            this.$store.dispatch("getMaterData",payLoad)
                .then((res)=>{
                // this[category] = res.list;
                 
                if(category =="user_role"){
                    //if loggied in user is super admin he can create only support team(2) AND CA admin (3)
                        if(this.userdata.roleId ==1){
                            this[category] =_.filter(res.list ,(rol)=>{
                                return rol._id ==2 || rol._id ==1
                                //return rol._id ==2 
                            });

                        }

                        //if loggied in user is CA admin he can create only Employes(4)
                        if(this.userdata.roleId ==3){
                            this[category] =_.filter(res.list ,{"_id":4 });

                        }
                }else{
                   this[category] = res.list;  
                }
                                        
                })
                .catch((err)=>{
                    this.log(err);

                });
        },
        
        applyFilters(isExpotrFile=false) {
             this.toggleBodyClass("removeClass", "hidden");
            
            this.fromFillingDetails  =false;
            let filterData = {"taxName":this.taxName, "fromFillingDetails":this.fromFillingDetails,"title": "","clientIds":[] ,"statusIds": [], "stateIds": [], "createdOnRange": [] ,"filedOnRange": [] ,"dueDateRange":[] , "taxTypeIds":[]};
            
            if((_.has(this.selected_filedOnRange ,'startDate' ) && this.selected_filedOnRange['startDate']  && this.selected_filedOnRange['startDate'] !=null ) &&
             ( _.has(this.selected_filedOnRange ,'endDate' ) && this.selected_filedOnRange['endDate']  && this.selected_filedOnRange['endDate'] !=null ) 
             
             ){
                filterData['filedOnRange'].push(moment(this.selected_filedOnRange['startDate']).format("YYYY-MM-DD"));
                filterData['filedOnRange'].push(moment(this.selected_filedOnRange['endDate']).format("YYYY-MM-DD"));
            }

            if((_.has(this.selected_createdDateRange ,'startDate' ) && this.selected_createdDateRange['startDate']  && this.selected_createdDateRange['startDate'] !=null ) &&
             ( _.has(this.selected_createdDateRange ,'endDate' ) && this.selected_createdDateRange['endDate']  && this.selected_createdDateRange['endDate'] !=null ) 
             
             ){
                filterData['createdOnRange'].push(moment(this.selected_createdDateRange['startDate']).format("YYYY-MM-DD"));
                filterData['createdOnRange'].push(moment(this.selected_createdDateRange['endDate']).format("YYYY-MM-DD"));
            }
        ///selected_dueDateRange
          if((_.has(this.selected_dueDateRange ,'startDate' ) && this.selected_dueDateRange['startDate']  && this.selected_dueDateRange['startDate'] !=null ) &&
            ( _.has(this.selected_dueDateRange ,'endDate' ) && this.selected_dueDateRange['endDate']  && this.selected_dueDateRange['endDate'] !=null ) 
             
             ){
                filterData['dueDateRange'].push(moment(this.selected_dueDateRange['startDate']).format("YYYY-MM-DD"));
                filterData['dueDateRange'].push(moment(this.selected_dueDateRange['endDate']).format("YYYY-MM-DD"));
            }

            //selectedCreatedByList
            if(this.selectedCreatedByList.length>0){
                _.forEach(this.selectedCreatedByList , (user)=>{
                    filterData.clientIds.push(user._id);

                });

            }
            //selected_status
             if(this.selected_status.length>0){
                _.forEach(this.selected_status , (user)=>{
                    filterData.statusIds.push(user._id);

                });

            }

            
            
            //selected_note_access_level
           if(this.selectedUsstateList.length>0){
                _.forEach(this.selectedUsstateList ,(state)=>{
                    filterData['stateIds'].push(state['_id']);
                });
            }

            //selectedTaxable
            if(this.selectedTaxable.length>0){
                _.forEach(this.selectedTaxable ,(obj)=>{
                    filterData['taxTypeIds'].push(obj['_id']);
                });
            } 
            if(_.has(this.selectedGlobalClient , "_id")){
                filterData['clientIds'] =[];
                filterData['clientIds'].push(this.selectedGlobalClient["_id"])

            }

            if(isExpotrFile){ 
             this.$emit("fileExport" ,filterData);

            }else{
                this.$emit("applyFilter" ,filterData);
            }
           
            this.openFilter=false;
            this.isLoaded =true;
        },
        clearFilter() {
             this.toggleBodyClass("removeClass", "hidden");
            this.note_access_level = [];
            this.selected_status = [];
            this.selectedCreatedByList = [];
            this.taggedToDetails = [];
            this.selected_note_access_level =[],
            this.selected_categoryIds =[];
            this.selectedUsstateList =[];
            this.selectedTaxable =[];
            //"selectedGlobalClient==null"
            this.fromFillingDetails =false;
            this.taxName ='';

             this.selected_createdDateRange['startDate'] =null;
            this.selected_createdDateRange['endDate'] = null;

            if(this.selectedGlobalClient !=null  && ( _.has(this.selectedGlobalClient , "_id")  ) ){
                this.selectedCreatedByList.push(this.selectedGlobalClient);
                 this.applyFilters();
            }else{
                 this.$emit("clearFilter");
            }
            this.selected_createdDateRange  = {
                startDate: null,
                endDate: null
              },
              this.selected_dueDateRange = {
                startDate: null,
                endDate: null
              }
            this.selected_filedOnRange = {
                startDate: null,
                endDate: null
            } 
           
         
           
            
            this.openFilter=false;
        },
       //////////////////////////////////////
        
      removeItem(index ,data){
          data.splice(index,1);

      },
      getCliList(limit=0) {
        let payLoad = {
		"matcher": {
			"searchString":"",
			//"cntryId": 101,
			//"stateId": 665,
			"cntryIds": [231], // Requied for "state" 
			//"stateIds": [665], // Requied for "city"
		},
		"page": 1,
		"perpage": limit>0?limit:10000000000,
		"category": "client_list", // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
	} ;
         this.$store.dispatch("getMaterData",payLoad)
         .then((res)=>{
             this.createdByList = res.list;
            

            this.selectedCreatedByList =[];
            if(this.$route.query.clientId){

                  _.forEach(this.createdByList ,(item)=>{
                     if(item._id ==this.$route.query.clientId ){
                         this.selectedCreatedByList =[];
                        this.selectedCreatedByList.push(item);
                       
                        

                     }

                 });


            }
             //this.applyFilters();
             

         });
       
        
      },
      
    

    },
    mounted(){
        this.fromFillingDetails = false;
       if( this.$route.query.stateId != null ) {
       // this.stateId = Number(this.$route.query.stateId);
       //  this.clientId = this.$route.query.clientId;
       // this.filingStartDate = this.$route.query.filingStartDate;
        // this.filingEndDate = this.$route.query.filingEndDate;

        this.selectedCreatedByList=[];
       
        this.selected_createdDateRange['startDate'] = moment(this.$route.query.filingStartDate);
        this.selected_createdDateRange['endDate'] = moment(this.$route.query.filingEndDate);
      
        this.taxName = this.$route.query.taxName;   
        this.fromFillingDetails =  true;
      }
      this.getMaterData("note_access_level");
      this.getUsStatesList();
      this.getCliList(100);
      
      
  },
  watch:{
      selectedGlobalClient:function(val){
            this.note_access_level = [];
            this.selected_status = [];
            this.selectedCreatedByList = [];
            this.taggedToDetails = [];
            this.selected_note_access_level =[],
            this.selected_categoryIds =[];
            if(val !=null  && (_.has(val , "_id")  ) ){
                this.selectedCreatedByList.push(val);

            }
            if(this.current_route =='transactions' && this.isLoaded ){
                this.applyFilters();

            }else {
              this.applyFilters();
            }
            
         

      }
  },
  computed:{
      current_route(){
        
        return this.$route.name.trim() ;
        
        
    },
  }
    
}
</script>